<template>
    <modal ref="modalRecomendacionesUso" titulo="Editar recomendaciones de uso" adicional="Guardar" tamano="modal-lg" @adicional="putReglas">
        <div v-if="verContenido">
            <ValidationObserver ref="validacion">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider v-slot="{errors}" tag="div" class="w-100" rules="required" name="recomendaciones">
                            <p class="f-12 pl-2 label-inputs">Recomendaciones de uso</p>
                            <el-input v-model="reglas" placeholder="Recomendaciones" type="textarea" autosize min-rows="4" />
                            <span class="text-danger f-10">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div v-else>
            <div class="row">
                <div class="col text-center">
                    <p>Cargando contenido...</p>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Service from '~/services/zonas'

export default {
    data(){
        return{
            verContenido: false,
            idZone: null,
            reglas: null,
        }
    },

    methods:{
        toggle(idZone){
            if(idZone != this.idZone){
                this.reglas = null;
                this.idZone = idZone;
                this.getData(idZone);
            }
            
            this.$refs.modalRecomendacionesUso.toggle();
        },

        async getData(idZone){
            try {
                const {data} = await Service.getRecomendacionesUso(idZone);
                this.reglas = data.recomendacion;
                setTimeout(() => {
                    this.verContenido = true;
                },100);
            } catch(e){
                this.errorCatch(e);
            }
        },

        async putReglas(){
            try {
                let validate = await this.$refs.validacion.validate()
            	if(!validate) return;

                const payload = {
                    reglas: this.reglas,
                };

                const {data} = await Service.putReglas(this.idZone, payload);
                if(data.exito){
                    this.clearAll();
                    this.$emit('update', data.reglas);
                    this.notificacion('Mensaje', 'Recomendación de uso actualizada', 'success');
                    this.$refs.modalRecomendacionesUso.toggle();
                }
            } catch(e){
                this.errorCatch(e);
            }
        },

        clearAll(){
            this.verContenido = false;
            this.idZone = null;
            this.reglas = null;
        },
    }
}
</script>
<style lang="scss" scoped>

</style>